/** @jsx jsx */
import { jsx, Box, Container, Flex, Styled } from "theme-ui"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import { HelmetDatoCms } from "gatsby-source-datocms"
import { Breadcrumb } from "gatsby-plugin-breadcrumb"
import "@reach/accordion/styles.css"

import {
  Hero,
  Overlay,
} from "@giraldomac/gatsby-theme-wvumedicine/src/components/elements"
import { Layout } from "@giraldomac/gatsby-theme-wvumedicine/src/components/layout"

import {
  ClinicService,
  ColorBar,
  ProvidersByLocation,
} from "../components/elements"

const Clinic = ({ data, pageContext }) => {
  const page = data.datoCmsChildrensClinic
  const {
    breadcrumb: { crumbs },
  } = pageContext

  return (
    <Layout>
      <HelmetDatoCms seo={page.seoMetaTags} />
      <Hero sx={{ position: "relative", overflow: "hidden", bg: "children.2" }}>
        <Container sx={{ position: "relative", px: "1em", zIndex: 10 }}>
          <Flex sx={{ flexWrap: "wrap", mx: [-4] }}>
            <Box sx={{ width: ["full", "full", "50%"], py: [5, 6], px: 4 }}>
              <Styled.h1>{page.title}</Styled.h1>
              <Breadcrumb
                crumbs={crumbs}
                crumbSeparator=" / "
                crumbLabel={page.title}
              />
            </Box>
            <Box sx={{ width: ["full", "full", "50%"], position: "relative" }}>
              <Img
                fluid={page.heroImage.fluid}
                objectFit="fill"
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                }}
              />
            </Box>
          </Flex>
        </Container>

        <ColorBar
          sx={{ position: "absolute", bottom: 0, left: 0, zIndex: 20 }}
        />
      </Hero>

      <Container sx={{ px: "1em", py: 5 }}>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
          }}
        >
          <Box
            as="main"
            sx={{
              flexGrow: 99999,
              flexBasis: 0,
              minWidth: 320,
            }}
          >
            {/* Content */}
            <Box as="section" id="content">
              <Flex sx={{ flexWrap: "wrap", mx: [0, 0, -4] }}>
                <Box sx={{ width: ["full", "full", "50%"], px: [0, 0, 4] }}>
                  <Box
                    width={["full"]}
                    dangerouslySetInnerHTML={{
                      __html: page.contentNode.childMarkdownRemark.html,
                    }}
                  />
                </Box>

                <Box
                  sx={{
                    width: ["full", "full", "50%"],
                    p: [4],
                    bg: "warmgray",
                  }}
                >
                  <Box as="h2" mt={2}>
                    Contact Us
                  </Box>
                  <p>
                    Appointments:{" "}
                    <a href={`tel:${page.phoneNumber}`}>{page.phoneNumber}</a>
                  </p>
                  <Box
                    width={["full"]}
                    dangerouslySetInnerHTML={{
                      __html: page.hoursNode.childMarkdownRemark.html,
                    }}
                  />
                    {page.locationId &&
                      <ProvidersByLocation id={page.locationId} />
                    }
                </Box>
              </Flex>
            </Box>
          </Box>
        </Box>
      </Container>

      {/* Services */}
      <Box as="section" id="services" sx={{ bg: "children.2" }} p={4}>
        <Container>
          <Box as="h2" sx={{ color: "white" }}>
            Services
          </Box>
          <Flex
            sx={{
              flexWrap: "wrap",
              mx: [0, 0, -4],
              justifyItems: "space-between",
              color: "white",
            }}
          >
            {page.offerings?.map(item => (
              <ClinicService item={item} key={item.id} />
            ))}
          </Flex>
        </Container>
      </Box>

      {/* Map */}
      {page?.googleMapUrl && (
        <Box as="section" id="services" sx={{ bg: "primary" }} py={4}>
          <Container>
            <Box
              width={["full"]}
              dangerouslySetInnerHTML={{
                __html: page?.googleMapUrl,
              }}
              sx={{ mx: "auto", textAlign: "center" }}
            />
          </Container>
        </Box>
      )}
    </Layout>
  )
}

export default Clinic

export const query = graphql`
  query ClinicQuery($slug: String!) {
    datoCmsChildrensClinic(slug: { eq: $slug }) {
      id
      title
      slug
      phoneNumber
      locationId
      addressNode {
        childMarkdownRemark {
          html
        }
      }
      contentNode {
        childMarkdownRemark {
          html
        }
      }
      heroImage {
        fluid(maxWidth: 400, imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsFluid
        }
      }
      hoursNode {
        childMarkdownRemark {
          html
        }
      }
      offerings {
        id
        service
        subservicesNode {
          childMarkdownRemark {
            html
          }
        }
      }
      googleMapUrl
      seoMetaTags {
        tags
      }
    }
  }
`
